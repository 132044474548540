@import "mixins";

.list {
  max-height: 360px;
  overflow-y: auto;
}

.item {
  padding: 12px 0;
}
