.component {
  white-space: pre;
}

.small {
  font-size: var(--font-size-small);
}

.block {
  display: block;
}
