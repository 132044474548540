@import "mixins";

.tabs {
  white-space: nowrap;
}

.card {
  background: var(--bg-muted);
  border: var(--border-width) solid var(--card-border);
  border-radius: var(--border-radius);
  font-size: 13px;
  font-weight: var(--bold);
  margin-bottom: 20px;
  overflow: hidden;

  @include mobile {
    display: flex;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}

.tab {
  @include inline-flex;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.card .tab {
  height: 60px;
  border-right: var(--border-width) solid var(--card-border);
  color: var(--text-muted);

  @include mobile {
    &:last-child {
      border-right: 0;
    }
  }

  &:hover {
    color: var(--text);
  }

  &.active {
    background: var(--card-bg);
    color: var(--text);
  }

  &.disabled {
    color: var(--text-muted);
  }

  @include desktop {
    padding: 0 30px;
  }

  @include mobile {
    flex: 1;
    text-align: center;
  }
}

.line {
  --border-color: var(--text);
}

.reversed {
  --border-color: var(--card-bg);
}

.line .tab {
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 12px;
  font-size: var(--font-size-small);
  font-weight: var(--bold);
  padding: 0 14px;
  padding-bottom: 5px;

  &.active {
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 4px;
  }
}

.disabled {
  @include disabled;
}
