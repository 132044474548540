@import "mixins";

.container {
  width: 100%;

  margin: 0 auto;
  overflow: hidden;

  @include desktop {
    max-width: var(--max-width);
  }
}
