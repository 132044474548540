.popover {
  min-width: 260px;
}

.link {
  color: var(--text);
  font-size: 18px;
  font-weight: var(--bold);
  margin-bottom: 4px;
}
